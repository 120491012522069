import { Mutation } from "@apollo/client/react/components";
import { ApplicationContext } from "@/components/ApplicationProvider";
import styled, {
  Form,
  Loader,
  StyleMixins,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeaderCell,
  TableRow,
} from "grabcad-ui-elements";
import { IOrder } from "@/graphql/Fragments/Order";
import { UPDATE_ORDER_DETAILS, UpdateOrderDetailsType } from "@/graphql/Mutations/Order";
import React, { useContext, useState } from "react";
import { FormattedDatePicker } from "../DatePicker/FormattedDatePicker";
import { OrderStatus } from "../Status/Status";
import { UiCan } from "@/components/UiCan";
import { Permission } from "@/utils/Permission";
import { Notifier } from "@/utils/Notifier";
import { fetchNewOrderEvents } from "@/graphql/Utils/updateEventsCacheUtil";
import { useApolloClient } from "@apollo/client";

interface IOrderDetailsProps {
  order: IOrder;
}

const StyledTable = styled(Table)`
  ${StyleMixins.roundAndShadow}
  // Override table row hover effect from grabcad-ui-elements since these aren't clickable
  &.ui.table tbody tr:hover {
    cursor: initial;
    background-color: inherit;
  }
`;

export const InfoTableRow = styled(TableRow)`
  display: flex;
  min-width: 715px;

  .order-date {
    flex: 0 1 196px;
    min-width: 160px;
  }
  .need-by-date {
    flex: 1 0 150px;
    .DateInput_input {
      max-width: 144px !important;
    }
  }
  .due-date {
    flex: 0 0 166px;
  }
  .status {
    flex: 0 1 166px;
    min-width: 150px;

    .ui.disabled.dropdown {
      opacity: 1;
      > .dropdown.icon {
        display: none;
      }
    }
    .ui.selection.dropdown {
      .text {
        white-space: normal;
      }
    }
  }
  .price {
    flex: 0 1 150px;
    text-align: right !important;
    min-width: 90px;
  }
  th {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const TotalPriceLoader = styled.div`
{
  position: relative;
  height: 100%;
  .ui.tiny.loader {
    position: absolute;
    right: 0;
    left: auto;
    top: 50%;
  }
`;

export const OrderInfo = ({ order }: IOrderDetailsProps): JSX.Element => {
  const { id, price, needByDate, dueDate, dateCreated } = order;
  const { t, formatDate, formatPrice } = useContext(ApplicationContext);
  const [orderDueDate, setOrderDueDate] = useState(dueDate);
  const [orderNeedByDate, setOrderNeedByDate] = useState(needByDate);
  const client = useApolloClient();
  const isAutoEstimationInProgress = order.orderItems.some(orderItem =>
    orderItem.autoEstimations?.some(
      autoEstimation => autoEstimation.progress || !autoEstimation.resultData
    )
  );

  return (
    <StyledTable data-testid="orderInfo">
      <TableHeader>
        <InfoTableRow>
          <TableHeaderCell className="order-date">{t("order.date")}</TableHeaderCell>
          <TableHeaderCell className="need-by-date">{t("order.needByDate")}</TableHeaderCell>
          <TableHeaderCell className="due-date">{t("order.dueDate")}</TableHeaderCell>
          <TableHeaderCell className="status">{t("order.status")}</TableHeaderCell>
          <TableHeaderCell className="price">{t("order.price")}</TableHeaderCell>
        </InfoTableRow>
      </TableHeader>
      <TableBody>
        <InfoTableRow>
          <TableCell className="order-date" data-testid="orderDateCell">
            {formatDate(dateCreated)}
          </TableCell>
          <TableCell className="need-by-date" data-testid="needByDateCell">
            <UiCan passThrough do={`${Permission.WRITE} needByDate`} on={order}>
              {(canWrite: boolean) =>
                canWrite ? (
                  <Mutation<
                    { updateOrderDetails: UpdateOrderDetailsType },
                    { input: UpdateOrderDetailsType & { id: number } }
                  >
                    mutation={UPDATE_ORDER_DETAILS}
                    update={async cache => fetchNewOrderEvents(cache, client, id)}
                    variables={{ input: { id, needByDate } }}
                    onError={error => Notifier.error(error)}
                  >
                    {mutate => (
                      <Form>
                        <FormattedDatePicker
                          id="qa-datePicker-needByDate"
                          value={orderNeedByDate}
                          onDateChange={moment => {
                            const isoString = moment ? moment.toISOString(false) : needByDate;
                            console.log(needByDate, isoString);
                            if (needByDate !== isoString) {
                              setOrderNeedByDate(isoString);
                              mutate({ variables: { input: { id, needByDate: isoString } } });
                            }
                          }}
                          disabled={!canWrite}
                        />
                      </Form>
                    )}
                  </Mutation>
                ) : (
                  formatDate(needByDate, { ignoreTimezone: true })
                )
              }
            </UiCan>
          </TableCell>
          <TableCell className="due-date" data-testid="dueDateCell">
            <UiCan passThrough do={`${Permission.WRITE} dueDate`} on={order}>
              {(canWrite: boolean) =>
                canWrite ? (
                  <Mutation<
                    { updateOrderDetails: UpdateOrderDetailsType },
                    { input: UpdateOrderDetailsType & { id: number } }
                  >
                    mutation={UPDATE_ORDER_DETAILS}
                    update={async cache => fetchNewOrderEvents(cache, client, id)}
                    onError={error => Notifier.error(error)}
                  >
                    {mutate => (
                      <Form>
                        <FormattedDatePicker
                          id="qa-datePicker-dueDate"
                          value={orderDueDate}
                          onDateChange={moment => {
                            const isoString = moment ? moment.toISOString(false) : orderDueDate;
                            if (orderDueDate !== isoString) {
                              setOrderDueDate(isoString);
                              mutate({ variables: { input: { id, dueDate: isoString } } });
                            }
                          }}
                          disabled={!canWrite}
                        />
                      </Form>
                    )}
                  </Mutation>
                ) : (
                  formatDate(orderDueDate, { ignoreTimezone: true })
                )
              }
            </UiCan>
          </TableCell>
          <TableCell className="status" id="qa-orderInfo-status" data-testid="statusCell">
            <OrderStatus entity={order} />
          </TableCell>
          <TableCell className="price" data-testid="priceCell">
            {isAutoEstimationInProgress ? (
              <TotalPriceLoader data-testid="totalPriceLoader">
                <Loader active={true} size="tiny" />
              </TotalPriceLoader>
            ) : (
              formatPrice(price)
            )}
          </TableCell>
        </InfoTableRow>
      </TableBody>
    </StyledTable>
  );
};
OrderInfo.displayName = "OrderInfo";
